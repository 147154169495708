var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-list"},[_c('div',{ref:"body",staticClass:"datatable-wrapper"},[_c('table',{staticClass:"datatable"},[_c('thead',{staticClass:"datatable-head"},[_c('tr',[_c('th',{staticClass:"name datatable-row-header",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("people.list.name"))+" ")]),_vm._l((_vm.yearRange),function(year){return (_vm.detailLevel === 'year')?_c('th',{key:'year-' + year,staticClass:"time year",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(year)+" ")]):_vm._e()}),_vm._l((_vm.monthRange),function(month){return (_vm.detailLevel === 'month')?_c('th',{key:'month-' + month,staticClass:"time month",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.monthToString(month))+" ")]):_vm._e()}),_vm._l((_vm.weekRange),function(week){return (_vm.detailLevel === 'week')?_c('th',{key:'week-' + week,staticClass:"daytime",attrs:{"scope":"col","title":_vm.getWeekTitle(week)}},[_vm._v(" "+_vm._s(week)+" ")]):_vm._e()}),_vm._l((_vm.dayRange),function(day){return (_vm.detailLevel === 'day')?_c('th',{key:'day-' + day,staticClass:"daytime",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(day)+" ")]):_vm._e()}),_c('th',{staticClass:"actions",attrs:{"scope":"col"}})],2)]),(!_vm.isLoading)?_c('tbody',{staticClass:"datatable-body"},_vm._l((_vm.people),function(person){return _c('tr',{key:person.id,staticClass:"datatable-row"},[_c('people-name-cell',{staticClass:"name",attrs:{"person":person}}),_vm._l((_vm.yearRange),function(year){return (_vm.detailLevel === 'year')?_c('td',{key:'year-' + year + '-' + person.id,class:{
              time: true,
              year: true,
              selected: _vm.isYearSelected(person.id, year)
            }},[(_vm.yearDuration(year, person.id) > 0)?_c('router-link',{staticClass:"duration",attrs:{"to":_vm.getYearDetailRoute(person, year)}},[_vm._v(" "+_vm._s(_vm.yearDuration(year, person.id))+" ")]):_c('span',[_vm._v(" - ")])],1):_vm._e()}),_vm._l((_vm.monthRange),function(month){return (_vm.detailLevel === 'month')?_c('td',{key:'month-' + month + '-' + person.id,class:{
              time: true,
              month: true,
              selected: _vm.isMonthSelected(person.id, _vm.year, month)
            }},[(_vm.monthDuration(month, person.id) > 0)?_c('router-link',{staticClass:"duration",attrs:{"to":_vm.getMonthDetailRoute(person, _vm.year, month)}},[_vm._v(" "+_vm._s(_vm.monthDuration(month, person.id))+" ")]):_c('span',[_vm._v(" - ")])],1):_vm._e()}),_vm._l((_vm.weekRange),function(week){return (_vm.detailLevel === 'week')?_c('td',{key:'week-' + week + '-' + person.id,class:{
              daytime: true,
              selected: _vm.isWeekSelected(person.id, _vm.year, week)
            }},[(_vm.weekDuration(week, person.id) > 0)?_c('router-link',{class:{
                duration: true,
                'warning': _vm.weekDuration(week, person.id) > 5 * _vm.organisation.hours_by_day
              },attrs:{"to":_vm.getWeekDetailRoute(person, _vm.year, week)}},[_vm._v(" "+_vm._s(_vm.weekDuration(week, person.id))+" ")]):_c('span',[_vm._v(" - ")])],1):_vm._e()}),_vm._l((_vm.dayRange),function(day){return (_vm.detailLevel === 'day')?_c('td',{key:'day-' + day + '-' + person.id,staticClass:"daytime",class:{
              daytime: true,
              weekend: _vm.isWeekend(_vm.year, _vm.month, day),
              selected: _vm.isDaySelected(person.id, _vm.year, _vm.month, day)
            }},[(_vm.dayDuration(day, person.id) > 0)?_c('router-link',{staticClass:"duration",attrs:{"to":_vm.getDayDetailRoute(person, _vm.year, _vm.month, day)}},[_vm._v(" "+_vm._s(_vm.dayDuration(day, person.id))+" ")]):(_vm.dayOffMap[person.id] &&
                             _vm.dayOffMap[person.id][("" + day)])?_c('span',[_vm._v(" OFF ")]):_c('span',[_vm._v(" - ")])],1):_vm._e()}),_c('td',{staticClass:"actions"})],2)}),0):_vm._e()])]),_c('table-info',{attrs:{"is-loading":_vm.isLoading,"is-error":_vm.isError}}),(!_vm.isLoading)?_c('p',{staticClass:"has-text-centered footer-info"},[_vm._v(" "+_vm._s(_vm.people.length)+" "+_vm._s(_vm.$tc('people.persons', _vm.people.length))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }